@import '../../node_modules/@cx/theme/src/base/_cx-bootstrap-variables.scss';

$activeSortArrowColor: gray;

.userList__stickyHeader {
  border-bottom: solid 1px lightgrey;
  color: #6d727a;
}

.userList__stickyHeader td {
  padding: 4px 0px;
}

.userList__grid {
  -ms-overflow-style: none;
}

.permissionmodal__roledropdown {
  margin-left: -17px !important;
  float: left;
}

.permissionmodal__legend {
  float: right;
}

.permissionmodal__reset {
  float: left;
}

.permissionmodal__username {
  display: inline-block;
}

.permissionmodal__customizedtext {
  margin-left: 10px;
}

.permissions__checkbox .cx-checkbox:nth-child(odd) {
  float: left;
  width: 50%;
  display: block;
  margin-top: 0px;
  margin-bottom: 8px;
}

.permissions__checkbox .cx-checkbox:nth-child(even) {
  float: right;
  width: 50%;
  display: block;
  margin-top: 0px;
  margin-bottom: 8px;
}

.clearfix {
  content: "";
  clear: both;
  display: table;
}

.userList__sortArrow {
  position: relative;
  padding: 0 !important;
  border: 0 !important;
}

.userList__sortUpArrowButton {
  top: -5px;
}

.userList__sortDownArrowButton {
  left: -24px;
  top: 10px;
}

.userList__sortArrow--active {
  color: $activeSortArrowColor !important;
  cursor: default;

  &:hover,
  &:focus {
    color: $activeSortArrowColor !important;
  }
}
