@import '../../node_modules/@cx/theme/src/base/_cx-bootstrap-variables.scss';

.userListCell {
  padding-top: 5px;
  background-color: white;
  border-bottom: solid 1px lightgray;
  white-space: nowrap;
}

.userListCell--hover {
  background-color: #f5f6f7;
}

.userListCell--active {
  background-color: #e3edff;
}

.userListCell__checkboxDiv {
  text-align: center;
}

.userListCell__checkbox {
  padding: 0;
  margin: 0;
  vertical-align: bottom;
  position: relative;
  top: -1px;
}

.userListCell__firstCell {
  border-left: 1px solid #cacfd9;
}

.userListCell__lastCell {
  border-right: 1px solid #cacfd9;
}

.userListCell__roleDropdown {
  padding: 0;

  button {
    padding: 0;
  }
}
