@import '../../node_modules/@cx/theme/src/base/_cx-bootstrap-variables.scss';

.enterprise__permissions__list {
  background-color: #f0f0f0;
  border-radius: 5px;
  padding: 25px 20px;
  width: 35%;
  align-items: center;
}

.ec__popover-tooltip .tooltip-inner {
  background-color: #fff;
  color: black;
  font-size: 14px;
  font-weight: 400;
}
