@import '../../node_modules/@cx/theme/src/base/_cx-bootstrap-variables.scss';

#InfoIcon {
  color: #2b6bdd;
  vertical-align: middle;
}
#InfoIcon:hover {
  cursor: pointer;
}

.enterprise__customer__container {
  display: flex;
  margin-left: 2px;
  margin-right: 12px !important;
}

.popover-tooltip .tooltip-inner {
  background-color: #fff;
  color: black;
  font-size: 14px;
  font-weight: 400;
}

.permission-checkbox {
  float: left; 
  width: 45%;
  display: flex;
  flex-direction: row;
  margin-bottom: 8px;
  align-items: center;

  .asterisk {
    width: 20px;
    height: 20px;
    text-align: center;
    
    span {
      line-height: 26px;
    }
  }

  input[type="checkbox"] {
    margin-right: 8px;
    margin-top: 0px;
  }
}


