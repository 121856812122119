@import '../../node_modules/@cx/theme/src/base/_cx-bootstrap-variables.scss';

html,
body {
  -ms-overflow-style: none;
}

body,
html,
main,
#root {
  height: 100vh;
}

.app--filterWrapper {
  float: left;
  width: 250px;
  margin-right: 20px;
}

/* Necessary to fix bug in CX SearchableSelect input styling */
.input-wrapper .rbt.clearfix {
  display: block;
  content: none;
}

.warningIcon {
  color: #ffaa00;
  vertical-align: middle;
  float: left;
}

.addedIcon {
  color: #38bf2c;
  vertical-align: middle;
}

.removedIcon {
  color: #dd2b2b;
  vertical-align: middle;
}

.header {
  font-size: 2rem;
}
