@import '../../node_modules/@cx/theme/src/base/_cx-bootstrap-variables.scss';

.multiuserbar__role-dropdown {
  margin-right: 32px;
}

.multiuserbar__container {
  padding: 8px;
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin: 8px 0 16px;
  background-color: #e4e9ef;
}

.multiUserBar__confirmDialogWarningIcon {
  color: #ffaa00;
  vertical-align: middle;
}
