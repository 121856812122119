@import '../../node_modules/@cx/theme/src/base/_cx-bootstrap-variables.scss';

#ManageRoleModal > .modal-dialog {
  width: 768px;
}

#ManageRoleModal__EC > .modal-dialog {
  width: 920px;
}

.permissions__main {
  width: 70%;
}

.enterprise__permissions__list {
  width: 30%;
}
.ManageRoleModal__reset {
  float: left;
}
.modal__roleName__input,
.modal__basedOn__input {
  display: flex;
  width: 50%;
}

#ManageRoleModal-ParentRoleDropDown-label,
#ManageRoleModal-RoleName-label {
  width: 75px;
}

.modal__roleName__input > .form-control,
.modal__basedOn__input > .select-wrapper {
  margin-left: 15px;
  width: 80%;
}

.filter__section {
  width: 75%;
}
.permissions__section {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.permissions__container {
  display: flex;
}

.permissions__categories {
  width: 100%;
}
.permissions__categories__EC {
  width: 70%;
}
