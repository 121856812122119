@import '../../node_modules/@cx/theme/src/base/_cx-bootstrap-variables.scss';

.filters__checkboxdropdown {
  border: 1px solid #b2b6bf;
  border-radius: 4px;
}

#filterUsersByName {
  height: 38px;
}
