@import '../../node_modules/@cx/theme/src/base/_cx-bootstrap-variables.scss';

// dropdown loses focus if list is too long
.open > .dropdown-menu {
  max-height: 400px;
  overflow: auto;
}

.warning__tooltip .tooltip-inner {
  background-color: #fff;
  color: black;
  font-size: 14px;
  font-weight: 400;
}
